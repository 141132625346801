import React from "react";
import "../Styles/Home.css";
// import productVideo from '../assets/dashboard_animation.mp4';
import productVideo from "../assets/product-video.mp4";
import dashboardImage from "../assets/hero-animation.svg";

import checkBox from "../assets/checkedbox.png";
import { Fade } from "react-reveal";
import { StorySlider, OtaSlider } from "../utils/Slider";
import Feature from "../Sections/Feature";
import WhyZonzo from "../Sections/WhyZonzo";


const Home = () => {
  return (
   
    <>
      {/* Hero section  */}
      <section className="hero">
        <Fade bottom cascade duration={1500}>
          <div className="hero-text">
            <h1>
              <span className="orange" id="one">One </span><span className="orange"> Platform</span> to 
              
              streamline all OTA's
            </h1>
            <h2>
              Anything is possible with the{" "}
              <span className="orange">most powerful</span> channel manager{" "}
               which will connect you to all top OTA's.
            </h2>
            <div className="btns">
              <div className="check">
                <div className="check-box">
                  <img src={checkBox} alt="checkbox" />
                  <p>Inventory Management</p>
                </div>
                <div className="check-box">
                  <img src={checkBox} alt="checkbox" />
                  <p>Rate Management</p>
                </div>
                <div className="check-box">
                  <img src={checkBox} alt="checkbox" />
                  <p>Single Booking Dashboard</p>
                </div>
              </div>
              <button>Try The Zonzo</button>
            </div>
          </div>
        </Fade>
        <Fade right distance="20%" duration={1700}>
          <div className="hero-image">
            <img src={dashboardImage} alt="hero" />
          </div>
        </Fade>
      </section>

      {/* OTA ScrollBar section  */}
     
      <section className="otas">
        <OtaSlider />
      </section>
       

      {/* product section  */}

      <section className="product">
        <Fade bottom duration={1800}>
          <div className="product-head">
            <h1>
              Drive <span className="orange">efficiency</span> across all Online
              Travel Agents
            </h1>
            <p>
              The Zonzo empower hotelier to manage their inventory, pricing and
              provide one Dashboard for bookings which leads to revenue
              maximization.
            </p>
          </div>
        </Fade>

        <div className="product-div">
         <Fade left duration={1500}>
         <div className="product-text">
            <div className="part">
              <h3>
                Increase Online <span className="orange">Visibility</span>
              </h3>
              <p>
                Create a single source of truth and align your teams and
                departments by centralizing all your work in one powerful
                platform.
              </p>
              <p>We also support your hotel to be listed in top OTA's</p>
              <button>Try The Zonzo</button>
            </div>
            <div className="part">
              <h3>
                <span className="orange">Eliminate</span> wasted efforts
              </h3>
              <p>
                Automate inventory to maximize revenue, minimize overbooking,
                and slash the time spent on routine tasks.
              </p>
              <p>
                By one click, you can update rate and inventory, real time
                connectivity to all channels.
              </p>
              <button>Try The Zonzo</button>
            </div>
            <div className="part">
              <h3>
                Deliver <span className="orange">Revenue</span> Faster
              </h3>
              <p>
                Align Room rate strategy by checking market insight which led to
                more revenue and data driven approach.
              </p>
              <p>
                By one click, you can update rate and inventory, real time
                connectivity to all channels.
              </p>
              <button>Try The Zonzo</button>
            </div>
          </div>
         </Fade>
         <Fade right distance="20%" duration={1700}>
          <div className={`product-video`}>
            <video id="productVideo" height="340px" src={productVideo}  autoPlay loop muted playbackRate={2.50} ></video>
          </div>
          </Fade>
        </div>
        
      </section>

      {/* Features section  */}
      <Feature />

      {/* Why zonzo */}
      <WhyZonzo />

      {/* stories section  */}
      <section className="stories">
        <h1>
          <span className="orange">Stories</span> from our Customers
        </h1>
        <div className="story-div">
          <StorySlider />
        </div>
      </section>

      {/* 2nd-last section  */}
      <section className="last">
      <Fade bottom cascade duration={1800}>
        <h1>
          Try our powerful <br /> Channel Management Software{" "}
        </h1>
        <p>Sign up for Zonzo today — no credit card required. </p>
        <form action="#">
          <input placeholder="Enter your email" />
          <button>Try The Zonzo </button>
        </form>
        </Fade>
      </section>
    </>
  );
};

export default Home;
