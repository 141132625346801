import React from 'react';
import '../Styles/Contact.css';
import callImage from '../assets/call-icon.png';
import addressImage from '../assets/icon-location.png';
import emailImage from '../assets/icon-email.png';
import { Fade } from 'react-reveal';

const Contact = () => {
  return (
    <section className='contact'>
      <Fade bottom cascade>
        <div className='contact-head'>
          <h1>Contact <span className='orange'>Us</span></h1>
          <p>We’re here to help and answer any question you might have. We look forward to hearing from you</p>
        </div>
      </Fade>

      <div className='contact-content'>
        <Fade left duration={1300} distance='30%'>
          <div className='contact-text'>
            <div className='contact-box'>
              <div className='image'>
                <img src={addressImage} alt='address-icon' />
              </div>
              <div className='text'>
                <h4>Address</h4>
                <p>Mothorawala, Dehradun <br />Uttrakhand</p>
              </div>
            </div>
            <div className='contact-box'>
              <div className='image'>
                <img src={callImage} alt='address-icon' />
              </div>
              <div className='text'>
                <h4>Phone</h4>
                <p>+91 9634134761</p>
              </div>
            </div>
            <div className='contact-box'>
              <div className='image'>
                <img src={emailImage} alt='address-icon' />
              </div>
              <div className='text'>
                <h4>Email</h4>
                <p>sales@thezonzo.com</p>
              </div>
            </div>
          </div>
        </Fade>

        <Fade right duration={1300} distance='30%'>
          <div className='message'>
            <h3>Send Message</h3>
            <Fade bottom cascade>
              <div className='inputs'>
                <input placeholder='Full Name' />
                <input placeholder='Email' />
                <input placeholder='Phone' />
                <textarea placeholder='Your message' />
              </div>
            </Fade>
            <button>Send</button>
          </div>
        </Fade>
      </div>
    </section>
  )
}

export default Contact