import React from "react";
import "../../Styles/JobDes.css";
import { Link } from "react-router-dom";
const FullStackD = () => {
  return (
    <div>
      <section className="job">
        <div className="goBack">
          <Link to="/workwithus">
            <button className="goBack-btn">Go Back</button>
          </Link>
        </div>

        <div className="title">
          <h1>
            Join <span className="orange">The Zonzo</span> Team
          </h1>
          <p>
            The Zonzo is a technology-driven startup founded in 2022 that offers
            hoteliers with solutions for revenue management, channel management,
            and property management. The company is committed to delivering the
            best possible ways to manage rooms distribution and price
            distribution, along with property management systems.
          </p>
        </div>
        <hr />
        <div className="main-container">
          <div className="left-side">
            <div className="job-description">
              <div className="job-title">
                <h1>
                  Profile Name:{" "}
                  <span className="orange">Full Stack Engineer</span>
                </h1>
              </div>
              <div className="job-role">
                <h3 className="h3colorMargin">Job Role: Full Stack Engineer</h3>
                <p>
                  This is a full-time remote role for a Full Stack Engineer. The
                  Full Stack Engineer will be responsible for back-end web
                  development, front-end development, software development &
                  Full-Stack Development. The Full Stack Engineer will use
                  Cascading Style Sheets (CSS) in order to contribute to the
                  development of the company's hotel technology solutions.
                </p>
              </div>

              <div className="roles-res">
                <h3 className="h3colorMargin">Qualifications:- </h3>
                <ul>
                  <li>
                  Experience with back-end web development (including experience with database technologies such as MySQL, MongoDB, etc.), front-end development (including proficiency with HTML, CSS, JavaScript, jQuery, etc.), and software development
                  </li>
                  <li>
                  Experience with Full-Stack Development
                  </li>
                  <li>
                  Proficiency in Cascading Style Sheets (CSS)
                  </li>
                  <li>
                  Experience with web application development frameworks such as Node.js, Ruby on Rails, Django etc.
                  </li>
                  <li>
                  Experience with cloud-based deployment platforms like AWS, GCP or Azure
                  </li>
                  <li>
                  Bachelor's or Master's Degree in Computer Science, related field or equivalent practical experience
                  </li>
                  <li>
                  Excellent problem solving and analytical skills
                  </li>
                  <li>
                  Ability to communicate effectively and work collaboratively with cross-functional teams, as well as be able to work independently
                  </li>
                  <li>
                  Knowledge of Agile software development methodologies is a plus
                  </li>
                  
                </ul>
              </div>
              <div className="eligibility">
                <h3 className="h3colorMargin">Eligibility Criteria:-</h3>
                <ul>
                  <li>Graduate/Post Graduate:- B.E/B.Tech, BCA, M.Tech, MCA, B.sc IT</li>
                  <li>
                    Work Experience:- In realated field &gt;2 Years
                  </li>
                  <li>Passionate for learning any technology</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="formContainer">
              <h1>
                Application <span className="orange">Form</span>{" "}
              </h1>
              <form action="" method="post">
                <div className="input-divs">
                  <label htmlFor="firstName">First Name</label>
                  <input type="text" name="name" id="firstName" required />
                </div>
                <div className="input-divs">
                  <label htmlFor="lastName">Last Name</label>
                  <input type="text" name="name" id="lastName" required />
                </div>
                <div className="input-divs">
                  <label htmlFor="phNo">Mobile Number</label>
                  <input
                    type="text"
                    pattern="[1-9]{1}[0-9]{9}"
                    name="phoneNumber"
                    id="phNo"
                    required
                  />
                </div>
                <div className="input-divs">
                  <label htmlFor="eMail">E-mail Address</label>
                  <input type="email" name="email" id="eMail" required />
                </div>
                <div className="input-divs upload-btn">
                  <label htmlFor="myFile">
                    Upload your updated resume &#40;.pdf, .doc format &#41;
                  </label>
                  <input
                    type="file"
                    id="myFile"
                    name="filename"
                    accept=".pdf, .doc"
                  />
                </div>
                <div className="formSubmit-btn">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FullStackD;
