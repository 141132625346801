import React, { useEffect, useState } from "react";
import {RiMenu3Line, RiCloseLine} from "react-icons/ri"
import "../Styles/Navbar.css";
import { Link, useLocation } from "react-router-dom";
import logoImage from "../assets/zonzologo/zonzologof1.png"
import { Fade } from "react-reveal";

const Navbar = () => {
  const location = useLocation();

  const [navSticky, setNavSticky] = useState(false);

  const[toggleMenu, setToggleMenu] = useState(false)

  useEffect(() => {
    const navScroll = () => {
      const top = window.scrollY;
      if (top > 0 && !navSticky) {
        setNavSticky(true);
      } else if (top === 0 && navSticky) {
        setNavSticky(false);
      }
    };

    window.addEventListener("scroll", navScroll);

    return () => {
      window.removeEventListener("scroll", navScroll);
    };
  }, [navSticky]);

  return (
    <>
    <nav className={`navbar ${navSticky ? "sticky" : ""}`}>
      <div className="logo">
        <Link to={"/"}>
          <img src={logoImage} alt="logo" />
        </Link>
        <div className="list">
          <ul>
            <Link to={"/about"}>
              <li className={location.pathname === "/about" ? "selected" : ""}>
                About Us
              </li>
            </Link>
            <Link to={"/contact"}>
              <li className={location.pathname === "/contact" ? "selected" : ""}>
                Contact Us
              </li>
            </Link>
            <Link to={"/workwithus"}>
              <li className={location.pathname === "/workwithus" ? "selected" : ""}>
                Join Us
              </li>
            </Link>
          </ul>
        </div>
      </div>
      <div className="login-btn">
        <Link to={"https://web.thezonzo.com/"}>
          <button className="login">Login</button>
        </Link>
      </div>
      <div className="sideMenu">
      <div className="sideMenu-login-btn">
        <button className="login">Login</button>
      </div>
        {toggleMenu
        ? <RiCloseLine color="#8f43e7" size={"27px"} onClick={()=>{setToggleMenu(false)}} /> 
        : <RiMenu3Line color="#8f43e7" size={"27px"} onClick={() =>{setToggleMenu(true)}}/>
      }

      {toggleMenu && (
          <Fade bottom cascade duration={1000}>
        <div className="sideMenu-container">
          <div className="sideMenu-links">
          <ul>
            <Link to={"/"}>
              <li className={location.pathname === "/" ? "selected" : ""}>
                Home
              </li>
            </Link>
            <Link to={"/about"}>
              <li className={location.pathname === "/about" ? "selected" : ""}>
                About Us
              </li>
            </Link>
            <Link to={"/contact"}>
              <li
                className={location.pathname === "/contact" ? "selected" : ""}
              >
                Contact Us
              </li>
            </Link>
            <Link to={"/workwithus"}>
              <li
                className={
                  location.pathname === "/workwithus" ? "selected" : ""
                }
              >
                Join Us
              </li>
            </Link>
          </ul>
          </div>
        </div>
        </Fade>
      )}
      </div>
    </nav>
    </>
  );
};

export default Navbar;
