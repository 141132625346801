import React from "react";
import "../Styles/About.css";
import { Fade } from "react-reveal";
import aboutimage from "../assets/aboutimage.jpg";
import ankur from "../assets/profile-pics/Ankursir1.png";
import manimadhurkar from "../assets/profile-pics/Manisir1.png";
import vikas from "../assets/vikas.jpg";

const About = () => {
  return (
    <section className="about">
      <Fade bottom cascade>
        <div className="contact-head">
          <h1>
            About <span className="orange">Us</span>
          </h1>
          <p>
            We are a dedicated team committed to helping hotels and travel
            agents succeed online
          </p>
        </div>
      </Fade>

      <div className="details">
        <Fade left duration={1200} distance="20%">
          <div className="about-text">
            <h2>
              So, who are <span className="orange">We</span>?
            </h2>
            <p>
              <b>VVAM Traveltech Solution Pvt Ltd (The Zonzo)</b> is a Travel
              Technology company ardently helping hotels and travel agents make
              more money by getting their business online.
            </p>
            <p>
              Our diversified teams who are experts in their jobs concentrate on
              the key areas of your business and develop customized products
              according to your needs offering complete value for money.
            </p>
            <p>
              Our business is about its people. People who are passionate, who
              have the ability to learn new things quickly, who exude boundless
              enthusiasm and most of all who are driven by a desire to make
              things we can all be proud of. People who want to do Good Work. We
              believe that as a team these people can achieve more than as
              individuals and that the best learning comes from working with
              others and sharing ideas.
            </p>
            <button>Contact Us</button>
          </div>
        </Fade>

        <Fade right duration={1200} distance="20%">
          <div className="about-image">
            <img src={aboutimage} alt="about" />
          </div>
        </Fade>
      </div>

      {/* partners section  */}

      <div className="partners">
        <Fade bottom>
          <h1>
            Your Partners in <span className="orange">Success</span>
          </h1>
        </Fade>

        <div className="profiles">
          <div className="profile">
            <Fade left duration={1200} distance="20%" cascade>
              <div className="profile-card">
                <div className="profile-image">
                  <img src={vikas} alt="profile" />
                </div>
                <h3>VIKAS CHAUHAN</h3>
                <p>
                  A decade-long track record of driving Business Development
                  within the Travel & E-commerce sectors, complemented by
                  concurrent proficiency in hotel management.
                </p>
              </div>
            </Fade>

            <Fade right duration={1200} distance="20%" cascade>
              <div className="profile-card">
                <div className="profile-image">
                  <img src={ankur} alt="profile" />
                </div>
                <h3>ANKUR KUMAR</h3>
                <p>
                  A decade of accomplished experience in Process Operation
                  Management and Customer Experience Excellence within the
                  dynamic realms of Ed-Tech and E-Commerce industries
                </p>
              </div>
            </Fade>
          </div>

          <Fade bottom cascade duration={1200}>
              <div className="last-div">
              <div className="profile-card">
                <div className="profile-image">
                  <img src={manimadhurkar} alt="profile" />
                </div>
                <h3>Dr. MANI MADHUKAR</h3>
                <h4>
                  Programm Manager <br />
                  IBM Pvt Ltd
                </h4>
              </div>
              </div>


            
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default About;
