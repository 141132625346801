import React from "react";
import "./Feature.css";
import { Fade } from "react-reveal";


import feature2 from "../assets/features/feature2.png";


const Feature = () => {
  return (
    <section className="feature">
      <Fade bottom cascade duration={1800}>
        <h1>
          The Zonzo Product <span className="orange">Features</span>
        </h1>
      </Fade>

    <div className="features-sec">
    
        <Fade bottom cascade duration={1800}>
          <div id="feature-img" className="features-img">
            <img src={feature2} alt="hero" />
          </div>
        </Fade>
     
      <Fade bottom cascade duration={1500}>
        <div className="feature-about">
          <h2>With The Zonzo</h2>
          <p>
            {" "}
            you get a comprehensive suite of features designed to streamline  <br className="none"/>
            your hotel management,enhance your visibility,and boost your
            revenue.
            <br className="none"/> Experience the future of hotel management with The Zonzo
            today.
          </p>
        </div>
      </Fade>
    </div>

    </section>
  );
};

export default Feature;
