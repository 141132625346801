import React from "react";
import "../../Styles/JobDes.css";
import "../../Styles/Home.css";
import { Link } from "react-router-dom";
const SalesManagerD = () => {
  return (
    <>
      <section className="job">
        <div className="goBack">
          <Link to="/workwithus">
            <button className="goBack-btn">Go Back</button>
          </Link>
        </div>

        <div className="title">
          <h1>
            Join <span className="orange">The Zonzo</span> Team
          </h1>
          <p>
            The Zonzo is a technology-driven startup founded in 2022 that offers
            hoteliers with solutions for revenue management, channel management,
            and property management. The company is committed to delivering the
            best possible ways to manage rooms distribution and price
            distribution, along with property management systems.
          </p>
        </div>
        <hr />
        <div className="main-container">
          <div className="left-side">
            <div className="job-description">
              <div className="job-title">
                <h1>
                  Profile Name: <span className="orange">Sales Officer</span>
                </h1>
              </div>
              <div className="job-role">
                <h3 className="h3colorMargin">
                  Job Role: Tele Sales Executive (SaaS)
                </h3>
                <ul>
                  <li>Industry: Hospitality/Technology</li>
                  <li>
                    Preferable Technology Requirements: Understanding for
                    Channel Manager
                  </li>
                  <li>CTC: 2.4 LPA to 3 LPA</li>
                  <li>
                    Incentives:- No Limit, totally depends on candidate how much
                    he/she can uplift.
                  </li>
                  <li>Position: 2</li>
                </ul>
              </div>

              <div className="roles-res">
                <h3 className="h3colorMargin">Roles & Responsibilities:- </h3>
                <ul>
                  <li>
                    Product Knowledge: Gain a deep understanding of the software
                    products you are selling, including their features,
                    benefits, and competitive advantages. You should be able to
                    explain how the software can address specific customer
                    needs.
                  </li>
                  <li>
                    Lead Generation: Identify and qualify potential leads by
                    conducting research, cold-calling, and responding to inbound
                    inquiries. This includes finding individuals or businesses
                    that may benefit from your software.
                  </li>
                  <li>
                    Solution Presentation: Tailor your sales pitch to match the
                    customer's needs. Highlight how your software can address
                    their specific challenges and provide value. Use product
                    demonstrations and presentations to illustrate the
                    software's capabilities.
                  </li>
                  <li>
                    Objection Handling: To address objections and concerns that
                    potential customers may have. This requires effective
                    communication skills and the ability to provide compelling
                    responses.
                  </li>
                  <li>
                    Closing Deals: Persuading prospects to make a purchase. This
                    includes negotiating terms, discussing pricing, and securing
                    commitments.
                  </li>
                  <li>
                    Relationship Building: Cultivate strong relationships with
                    customers to build trust and rapport. Maintain ongoing
                    communication to nurture long-term customer relationships.
                  </li>
                  <li>
                    Sales Pipeline Management: Keep accurate records of leads,
                    prospects, and sales activities. Track the progress of leads
                    through the sales funnel.
                  </li>
                  <li>
                    Target Achievement: Set and work towards sales targets and
                    quotas. Monitor your sales performance and take proactive
                    steps to meet or exceed your goals.
                  </li>
                  <li>
                    Market Research: Stay updated on industry trends, competitor
                    offerings, and market dynamics.
                  </li>
                  <li>
                    Compliance: Ensure that your sales activities comply with
                    legal and ethical standards, including data protection
                    regulations and industry-specific rules.
                  </li>
                  <li>
                    Feedback Loop: Provide feedback to the product development
                    and marketing teams based on customer interactions. Share
                    insights on customer needs and preferences to help improve
                    the software.
                  </li>
                  <li>
                    Team Collaboration: Work collaboratively with other members
                    of the sales team, as well as marketing and support teams,
                    to achieve common objectives and provide a seamless customer
                    experience.
                  </li>
                </ul>
              </div>
              <div className="eligibility">
                <h3 className="h3colorMargin">Eligibility Criteria:-</h3>
                <ul>
                  <li>Post Graduate:- BBA/MBA Marketing & Sales</li>
                  <li>
                    Work Experience:- Specifically Tele Sales, &gt;2 Years
                  </li>
                  <li>Interested:- Traveling</li>
                  <li>Passionate for sales</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="formContainer">
              <h1>Application <span className="orange">Form</span> </h1>
              <form action="" method="post">
                <div className="input-divs">
                  <label htmlFor="firstName">First Name</label>
                  <input type="text" name="name" id="firstName" required />
                </div>
                <div className="input-divs">
                  <label htmlFor="lastName">Last Name</label>
                  <input type="text" name="name" id="lastName" required />
                </div>
                <div className="input-divs">
                  <label htmlFor="phNo">Mobile Number</label>
                  <input type="text" pattern="[1-9]{1}[0-9]{9}" name="phoneNumber" id="phNo" required />
                </div>
                <div className="input-divs">
                  <label htmlFor="eMail">E-mail Address</label>
                  <input type="email" name="email" id="eMail" required />
                </div>
                <div className="input-divs upload-btn">
                  <label htmlFor="myFile">Upload your updated resume &#40;.pdf, .doc format  &#41;</label>
                 <input type="file" id="myFile" name="filename" accept=".pdf, .doc" />
                </div>
                <div className="formSubmit-btn">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SalesManagerD;
