import React from 'react';
import './WhyZonzo.css';
import logoImage from '../assets/logo.png';
import Fade from 'react-reveal/Fade';

const WhyZonzo = () => {
    return (
        <div className="why-zonzo">
            <div className="information">
                <h1>Elevate Your Business with Expertise in Technology and Hospitality</h1>
                
            </div>
            <div className="second">
            <Fade left  duration={1500}>
                <div className="content">
                    <h2>Why Zonzo?</h2>
                    <p> Because we are not just another software solution. We are a team of seasoned experts who understand the intricate demands of the hospitality industry. We've walked in the shoes of hoteliers, and we know the hurdles that can impede your business's smooth growth.</p>
                    <p>Our commitment to the hospitality community runs deep. We understand the pains and aspirations of hoteliers striving to bring a corporate edge to their establishments.</p>
                </div>
                </Fade>
                <Fade right  duration={1500}>
                <div className="logo-pics">
                    <img src={logoImage} alt="otas" srcset="" />
                </div>
                </Fade>
            </div>

            <div className="card-info">
                <h1>Here's why you should choose<span class="orange"> Zonzo</span></h1>
                <div className="info-cards">
                <Fade bottom cascade duration={1500}>
                    <div className="info-card">
                        <h3>Expertise at Your Service</h3>
                        <p>Our platform is developed by a team that combines technological prowess with hands-on experience in the hospitality sector. We've been where you are and have designed Zonzo to be your trusted ally in overcoming operational challenges.</p>
                    </div>
                    </Fade>
                    <Fade bottom cascade  duration={1700}>
                    <div className="info-card">
                        <h3>Dedicated to Hospitality</h3>
                        <p>We are committed to supporting the hospitality community, and our mission is to empower you with the tools and insights needed to thrive in a competitive landscape.</p>
                    </div>
                    </Fade>
                    <Fade bottom cascade duration={1900}>
                    <div className="info-card">
                        <h3>Your Growth Partner</h3>
                        <p>We comprehend the journey of a hotelier who envisions transforming their business into a corporate success story. With Zonzo, you have a partner dedicated to helping you achieve that vision.</p>
                    </div>
                    </Fade>
                </div>
            </div>
            <div class="info-last">
                <p>Choose Zonzo and experience the difference expertise, dedication, and commitment can make in transforming your hotel management. Elevate your business with us today.</p>
            </div>
            
        </div>
        

    )
}

export default WhyZonzo;