import React from "react";
import storyImage1 from "../assets/stories1.jpeg";
import storyImage2 from "../assets/stories2.jpeg";
import storyImage3 from "../assets/stories3.jpg";
import storyImage4 from "../assets/stories4.jpg";
import storyImage5 from "../assets/stories5.jpg";
import storyImage6 from "../assets/stories6.jpg";
import ota1 from "../assets/agoda.png";
import ota2 from "../assets/airbnb.png";
import ota3 from "../assets/booking.png";
import ota4 from "../assets/cleartrip.png";
import ota5 from "../assets/yatra.png";
import ota6 from "../assets/expedia.png";
import ota7 from "../assets/go-mmt.png";
import ota8 from "../assets/goibibo.png";
import ota9 from "../assets/easemytrip.png";
import ota10 from "../assets/happyeasygo.png";
import ota11 from "../assets/hobse-logo.png";
import ota12 from "../assets/Hostelworld.png";
import ota13 from "../assets/travelguru_logo.png";
import "./Slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const StorySlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 6000,
    cssEase: "linear",
    // variableWidth:true,
  };

  return (
    <Slider {...settings}>
      <div>
        <div className="slider-div">
          <div className="story-image">
            <img src={storyImage1} alt="story" />
          </div>
          <div className="story-text">
            <h3>
              "The Zonzo software can do a lot of things for all kinds of
              hotels.
              <br /> What I love about The Zonzo Ultimate package as a whole is
              the cost for the software -<br /> it is very flexible and
              reasonable."
            </h3>
            <p>
              - <span className="orange">Alinda House</span>, Nainital
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="slider-div">
          <div className="story-image">
            <img src={storyImage2} alt="story" />
          </div>
          <div className="story-text">
            <h3>
              "Right from the conversations with the sales team, to the software
              implementation at our property, to round the clock support team;
              The Zonzo is excellent in all aspects. They have been extremely
              patient with our doubts, and dealt it with professional
              expertise."
            </h3>
            <p>
              - <span className="orange">Green View Doon</span>, Uttrakhand
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="slider-div">
          <div className="story-image">
            <img src={storyImage3} alt="story" />
          </div>
          <div className="story-text">
            <h3>
              " The channel manager system is easy to operate. All updates
              requested are handled efficiently. I am very happy with the
              system. The staff also has been very cooperative in handling any
              issues I faced. Have recommended The Zonzo to various hotel."
            </h3>
            <p>
              - <span className="orange">Jungle Tale</span>, Dehradun
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="slider-div">
          <div className="story-image">
            <img src={storyImage4} alt="story" />
          </div>
          <div className="story-text">
            <h3>
              " Initially, managing multiple reservation platforms was a very
              time consuming task. The Zonzo is the only company that provides
              easy to use reservation technology at a reasonable price. Now,
              because of The Zonzo, we are able to sell more rooms and update
              the inventory quickly."
            </h3>
            <p>
              - <span className="orange">Samarpan cottage </span>, Bhimtal
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="slider-div">
          <div className="story-image">
            <img src={storyImage5} alt="story" />
          </div>
          <div className="story-text">
            <h3>
              "The training session has especially helped me a lot in learning
              about the product.
              <br /> The Zonzo is a really well-planned product. I feel very
              secure while working with the system now."
            </h3>
            <p>
              -<span className="orange">The Desi Rootz Hotel & Restourant</span>
              , Corbett
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="slider-div">
          <div className="story-image">
            <img src={storyImage6} alt="story" />
          </div>
          <div className="story-text">
            <h3>
              " Now, I do not need an employee to update the inventory everyday.
              The Live update of room inventory on OTAs happens with only a
              click of a button. Because of this, uploading inventory and
              changing room rates has become fairly simple. Of course, nothing
              beats the 24 hour support."
            </h3>
            <p>
              - <span className="orange">Expaat Home</span>, Gurugram
            </p>
          </div>
        </div>
      </div>
    </Slider>
  );
};

// export default StorySlider;

const OtaSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    variableWidth: true,
  };

  return (
    <Slider {...settings}>
      <div id="agoda" className="ota">
        <img src={ota1} alt="ota" className="ota-image" />
      </div>

      <div id="happyeasy" className="ota">
        <img id="happyeasy" src={ota10} alt="ota" />
      </div>

      <div id="airbnb" className="ota">
        <img src={ota2} alt="ota" className="ota-image" />
      </div>

      <div className="ota">
        <img id="easemytrip" src={ota9} alt="ota" className="ota-image" />
      </div>
      <div id="goiboibo" className="ota">
        <img src={ota8} alt="ota" className="ota-image" />
      </div>
      {/* <div className="ota">
        <img   id="via" src={ota14} alt="ota"className="ota-image" />
      </div> */}

      <div className="ota">
        <img id="hobse" src={ota11} alt="ota" className="ota-image" />
      </div>
      <div id="cleartrip" className="ota">
        <img src={ota4} alt="ota" className="ota-image" />
      </div>
      <div className="ota">
        <img id="hostelworld" src={ota12} alt="ota" className="ota-image" />
      </div>
      <div id="expedia" className="ota">
        <img src={ota6} alt="ota" className="ota-image" />
      </div>
      <div className="ota">
        <img id="travelguru" src={ota13} alt="ota" className="ota-image" />
      </div>
      <div id="yatra" className="ota">
        <img src={ota5} alt="ota" className="ota-image" />
      </div>
      <div className="ota">
        <img id="go-mmt" src={ota7} alt="ota" className="ota-image" />
      </div>
      <div className="ota">
        <img id="booking" src={ota3} alt="ota" className="ota-image" />
      </div>
    </Slider>
  );
};

export { StorySlider, OtaSlider };
