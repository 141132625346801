// import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Navbar from "./Components/Navbar";
import Home from './Pages/Home';
import Footer from "./Components/Footer";
import About from './Pages/About';
import Contact from './Pages/Contact';
import ScrollToTop from './utils/ScrollToTop';
import Join from './Pages/Join';

import SalesManagerJob from "./Pages/job description/SalesManagerD"
import FullStackJob from "./Pages/job description/FullStackD"

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/about' exact element={<About />} />
        <Route path='/contact' exact element={<Contact />} />
        <Route path='/workwithus' exact element={<Join />} />
        <Route path='/SalesManagerJob' exact element={<SalesManagerJob />} />
        <Route path='/FullStackJob' exact element={<FullStackJob />} />
      </Routes>
      <Footer />
    </Router>

  );
}

export default App;
