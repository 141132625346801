import React from "react";
import "../Styles/Footer.css";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import logoImage from "../assets/zonzologo/zonzologow3.png";

const Footer = () => {
  return (
    <section className="footer">
      <div className="footer-bottom">
        <Fade left cascade duration={1200} distance="20%">
          <div className="logo-container footer-div">
            <div className="logo-div">
              <img src={logoImage} alt="logo" />
            </div>
            <div className="footerLogoSubtitle">
              <p>
                Make the right data-driven decisions that move your business
              </p>
            </div>
          </div>
        </Fade>

        <Fade bottom cascade>
          <div className="footer-div">
            <h3>Useful Links</h3>
            <ul>
              <Link to={"/about"}>
                {" "}
                <li>About Us</li>
              </Link>
              <Link to={"/contact"}>
                {" "}
                <li>Contact Us</li>
              </Link>
              <Link to={"/workwithus"}>
                {" "}
                <li>Join Us</li>
              </Link>
            </ul>
          </div>
        </Fade>

        <Fade right cascade duration={1200} distance="20%">
          <div className="footer-div">
            <h3>Connect With Us</h3>
            <ul>
              <li>+91 9634134761</li>
              <li>sales@thezonzo.com</li>
              <li>Dehradun, Uttrakhand</li>
            </ul>
          </div>
        </Fade>
      </div>

      <Fade bottom cascade>
        <hr />

        <div className="copyright">
          <h3>
            All Rights Reserved &#169; <span className="orange">The Zonzo</span>{" "}
            2023
          </h3>
        </div>
      </Fade>
    </section>
  );
};

export default Footer;
