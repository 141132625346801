import React from "react";
import "../Styles/Join.css";
import { Fade } from "react-reveal";
import {Link} from "react-router-dom"

const Join = () => {
  return (
    <section className="join">
      <Fade bottom cascade>
        <div className="contact-head">
          <h1>
            Start your career at <span className="orange">The Zonzo</span>
          </h1>
          <p>
            We are always on the lookout for talented individuals to join our
            growing team. Please see our open vacancies below
          </p>
        </div>
      </Fade>
      <div className="jobs">
        <Fade bottom cascade>
          <div className="jobs-head">
            <div className="job-title">
              <h1>Sales Manager</h1>
              <p>Experience: &gt;2 years</p>
            </div>
            <div className="job-des">
              <p>
                A Sales Manager at The Zonzo will be responsible for explaining
                how the software can address specific customer needs. Identify
                and qualify potential leads and cultivate strong relationships
                with cutomers to ...
              </p>
            </div>
            <Link to="/SalesManagerJob"><button className="apply">Read More</button></Link>
          </div>
        </Fade>
        <Fade bottom cascade>
          <div className="jobs-head">
            <div className="job-title">
              <h1>Full Stack Engineer</h1>
              <p>Experience: &gt;2 years</p>
            </div>
            <div className="job-des">
              <p>
                A Full Stack Engineer at The Zonzo will be will be responsible
                for back-end web development, front-end development, software
                development & Full-Stack Development. The Full Stack ...
              </p>
            </div>
            <Link to="/FullStackJob"><button className="apply">Read More</button></Link>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Join;
